<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Permission Management</h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDialogPermission"
        v-if="canAccess('permission-create')"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Add Permission</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="6">
            <v-pagination
              class="mt-5"
              color="#00BCD4"
              style="float: left"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="6">
            <b-form-input
              class="mt-6"
              style="float: right; width: 300px"
              placeholder="Search"
              v-model="search_input"
            ></b-form-input>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Code</th>
                    <th scope="col">Module</th>
                    <th scope="col">Note</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(permission, i) in permissions">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ permission.name }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ permission.code }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ displayTextModule(permission.module) }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ permission.note }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editPermission(permission)"
                                v-if="canAccess('permission-edit')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <!-- <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button class="btn btn-icon btn-light-danger btn-sm ml-2" v-bind="attrs" v-on="on" @click="btnDeletePer(permission.id)" v-if="canAccess('permission-delete')">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip> -->
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!permissions.length">
                    <tr>
                      <td style="text-align: center" colspan="7">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogPermission" max-width="1000px" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_permission == 'add'" class="headline"
                >Add Permission</span
              >
              <span v-if="type_form_permission == 'update'" class="headline"
                >Edit Permission</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogPermission = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="10" class="pt-0">
                  <v-text-field
                    label="Name"
                    v-model="name_input"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="10">
                  <v-text-field
                    label="Code"
                    v-model="code_input"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="10">
                  <div class="d-flex">
                    <label style="font-size: 16px">Note</label>
                    <v-textarea
                      placeholder="Enter text..."
                      class="pt-0 pl-8"
                      rows="4"
                      auto-grow
                      v-model.trim="note_input"
                      solo
                    ></v-textarea>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="d-flex">
                    <label style="font-size: 16px">Module: </label>
                    <v-radio-group
                      class="mb-0 pb-0 mt-0 pt-0 ml-2"
                      v-model="module_input"
                    >
                      <template v-for="(prep_module, i) in prep_modules">
                        <v-radio
                          :label="prep_module.label"
                          :value="prep_module.value"
                          :key="i"
                        ></v-radio>
                      </template>
                    </v-radio-group>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_permission == 'add'"
              @click="btnAddOrEditPer(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_permission == 'update'"
              @click="btnAddOrEditPer(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogPermission = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import _ from "lodash";
export default {
  name: "PermissionList",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      permissions: [],
      prep_modules: [{ label: "", value: null }],
      search_input: "",
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
      type_form_permission: "",
      dialogPermission: false,
      name_input: "",
      code_input: "",
      module_input: "",
      note_input: "",
      id_permission: null,
    };
  },
  watch: {
    "data_pagination.current_page": function (page) {
      this.getAllPermission(page, this.search_input);
    },
    search_input: function (val) {
      this.actionSearchPer(1, val);
    },
  },
  created() {
    this.is_call_api = true;
    this.getAllPermission();
    this.getAllModule();
    this.is_call_api = false;
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    async getAllPermission(page = 1, query = "") {
      let vm = this;
      await ApiService.get(
        "prep-app/permission?page=" + page + "&name=" + query
      ).then(function (response) {
        if (response.status == 200) {
          vm.permissions = response.data.data;
          vm.data_pagination = response.data.meta.pagination;
        }
      });
    },
    async getAllModule() {
      let vm = this;
      await ApiService.get("prep-app/permission/modules").then(function (
        response
      ) {
        if (response.status == 200) {
          vm.prep_modules = response.data;
        }
      });
    },
    displayTextModule(value) {
      if (value == null || value == undefined || value == "") {
        return null;
      }
      let result = this.prep_modules.filter(
        (moduleItem) => moduleItem.value == value
      );
      return result[0].label;
    },
    actionSearchPer: _.debounce(function (page = 1, query = "") {
      let seft = this;
      seft.is_call_api = true;
      ApiService.get(
        "prep-app/permission?page=" + page + "&name=" + query
      ).then(function (response) {
        if (response.status == 200) {
          seft.is_call_api = false;
          seft.permissions = response.data.data;
          seft.data_pagination = response.data.meta.pagination;
        }
      });
    }, 1200),
    btnDeletePer(per_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.delete("prep-app/permission/" + per_id)
            .then(function (res) {
              if (res.status == 202) {
                vm.is_call_api = false;
                vm.getAllPermission(
                  vm.data_pagination.current_page,
                  vm.search_input
                );
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
    openDialogPermission() {
      this.dialogPermission = true;
      this.type_form_permission = "add";
      this.name_input = "";
      this.code_input = "";
      this.module_input = "";
      this.note_input = "";
    },
    async btnAddOrEditPer(type) {
      let vm = this;
      let data = {
        name: this.name_input,
        code: this.code_input,
        note: this.note_input,
      };
      if (this.module_input) data.module = parseInt(this.module_input);
      if (type == 1) {
        vm.is_call_api = true;
        await ApiService.post("prep-app/permission", data)
          .then(function (res) {
            if (res.status == 200) {
              vm.getAllPermission();
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.is_call_api = false;
              vm.dialogPermission = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status == 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        vm.is_call_api = true;
        await ApiService.post("prep-app/permission/" + vm.id_permission, data)
          .then(function (res) {
            if (res.status == 202) {
              vm.getAllPermission();
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.is_call_api = false;
              vm.dialogPermission = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status == 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    editPermission(permission) {
      this.type_form_permission = "update";
      this.id_permission = permission.id;
      this.name_input = permission.name;
      this.code_input = permission.code;
      this.note_input = permission.note;
      this.module_input = permission.module != null ? permission.module : 0;
      this.dialogPermission = true;
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
